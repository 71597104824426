const commonEnvironments = (baseUrl: string) => ({
  breakerToken: `${baseUrl}/auth/login`,
  verifyOtp: `${baseUrl}/auth/verifyOtp`,
  resendCode: `${baseUrl}/auth/resendCode`,
  introspectionEndpoint: `${baseUrl}/auth//token/introspect`,
  singleSms: 'https://dev-sms-api.qualisdigital.co//sms/message/send',
  bulkSmsUpload: `${baseUrl}//user/bulkupload`,
  bulkSmsHistory: `${baseUrl}/sms/bulk-sms-history`,
  smsHistory: `${baseUrl}/sms/sms-history`,
  smsStats: `${baseUrl}/sms/stats`,
  smsBulkFileExtracts: `${baseUrl}/sms/fetch-extracts`,
  smsBulkFileCounts: `${baseUrl}/sms/fetch-extracts-count`,
  resetPassword: `${baseUrl}/auth/reset-password`,
  validateResetCode: `${baseUrl}/auth/validate-token`,
  createPassword: `${baseUrl}/auth/create-password`,
  fetchAllCollections: `${baseUrl}/customer/payments`,
  fetchCollectionStats: `${baseUrl}/customer/payment-stats`,
  fetchAllCustomers: `${baseUrl}/customer/fetch-all-users`,
  fetchSingleCustomer: `${baseUrl}/customer`,
  searchAllCustomers: `${baseUrl}/customer/search`,
  customerSync: `${baseUrl}/customer/profile-sync`,
  loanSync: `${baseUrl}/customer/loan-sync`,
  cardHistory: `${baseUrl}/card/history`,
  customersWithCard: `${baseUrl}/card/fetch-all-customers`,
  fetchSinglePayment: `${baseUrl}/customer/fetch-payment`,
  dashboardPaymentStats: `${baseUrl}/customer/payment/stats`,
  fetchPaymentHistory: `${baseUrl}/customer/fetch-payments`,
  sendLinkForAddCard: `${baseUrl}/card/link`,
  cardDirectDebit: `${baseUrl}/customer/loan/card/pay`,
  bulkCardDeduct: `${baseUrl}/card/bulk`,
  fetchData: 'https://api.jsonbin.io/v3/b/64d6e6818e4aa6225eceb054',
  fakeData: 'https://jsonplaceholder.typicode.com/albums',
  testing: 'https://enhpzwr4lk1dc.x.pipedream.net/',
});

export default commonEnvironments;

export const googleAnalyticsTitle = {
  PAGE_VIEW: 'page_view',
  PRODUCT_NAME: 'qualis web',
  EVENT_CATEGORY: 'qualis',
};

export const pageTitle = {
  HOMEPAGE: 'Homepage',
  FAQ: 'FAQ Page',
  STARTA: 'Starta Loan',
  SKULU: 'skulu Loan',
  DEVICE: 'device Loan',
  CONTACT: 'Contact Page',
  ABOUT_US: 'About Us Page',
  APPLICATION_CIVILSERVANT: 'Civil Servant Loan Application',
  APPLICATION_PERSONAL: 'Personal Loan Application',
  APPLICATION_MOU: 'MOU loan Application',
  APPLICATION_STARTA: 'Starta Loan Application',
  APPLICATION_SKULU: 'Skulu Loan Application',
  APPLICATION_DEVICE: 'Device Loan application',
};
